export const menuItems = [
  {
    icon: 'file-1',
    activeIcon: 'file-active',
    href: '/documents',
    text: 'Documents',
    visible: true
  },
  {
    icon: 'template',
    activeIcon: 'template-active',
    href: '/templates',
    text: 'Templates',
    visible: true
  },
  {
    icon: 'archive-3',
    activeIcon: 'archive-active',
    href: '/documents/archive',
    text: 'Archive',
    visible: true
  }
]
