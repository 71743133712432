<template lang="pug">
  <!-- TODO: Check isGuest -->
  v-menu(
    v-if="$User && !isGuest"
    v-model="profileMenu"
    offset-y
    content-class="profile-menu__content"
    :close-on-content-click="false"
  )
    template(v-slot:activator="{ on }")
      v-btn(
        v-on="on"
        class="profile-button"
        :class="{ 'circled': !currentOrganization || xsDevice }"
        :icon="!currentOrganization || xsDevice"
        height="48"
        @click="profileMenu = !profileMenu"
      )
        template(v-if="currentOrganization && !xsDevice")
          v-img(:src="require('~/assets/images/bagcase.png')" max-width="16")
          span(v-if="currentOrganizationName" class="profile-button__text px-2") {{ currentOrganizationName }}
          span(class="profile-button__arrow" :class="{ 'active': profileMenu }")
            e-svg-icon(size="xsm") arrow-down
        template(v-else)
          v-img(v-if="!profileMenu" :src="require('~/assets/images/person.png')" max-width="16")
          v-icon(v-else) mdi-close
    div(class="profile-menu")
      div(class="profile-menu__icon")
        v-img(:src="require('~/assets/images/person.png')" max-width="16")
      div(class="profile-menu__title") {{ $t('Welcome') }}
      div(class="profile-menu__email") {{ $User.email }}
      div(class="px-3 w-100")
        e-organization-change(class="mb-4")
      v-divider(class="w-100")
      v-list(dense class="w-100 py-0")
        v-list-item-group
          v-list-item(class="py-2" dense @click="goToProfile")
            v-list-item-title(class="link__text") {{ $t('My account') }}
          v-list-item(class="py-2" dense @click="goToOrganizations")
            v-list-item-title(class="link__text") {{ $t('My organizations') }}
      v-divider(class="w-100")
      v-list(dense class="w-100 py-0")
        v-list-item-group
          v-list-item(class="py-2" @click="logout")
            v-list-item-title(class="link__text") {{ $t('Exit') }}
</template>

<script>
import EOrganizationChange from '~/modules/organizations/views/components/e-organization-change'
import auth from '~/modules/auth/auth'
import taxAuth from '~/mixins/pages/tax-auth'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import CurrentOrganizationMixin from '~/modules/organizations/mixins/currentOrganizationMixin'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockProfileMenu',
  components: {
    EOrganizationChange,
    ESvgIcon
  },
  mixins: [taxAuth, CurrentOrganizationMixin, responsive],
  data () {
    return {
      profileMenu: false
    }
  },
  computed: {
    currentOrganizationName () {
      return this._.get(this.currentOrganization, 'name', null)
    },
    isGuest () {
      return this._.get(this.$route, 'query.token', null)
    }
  },
  methods: {
    goToProfile () {
      this.$router.push('/profile')
    },
    goToOrganizations () {
      this.$router.push('/my-organizations')
    },
    async logout () {
      await auth.logout()
      this.destroyIitGlobalDialog()
      await this.$router.push({ name: 'auth' })
    }
  }
}
</script>

<style scoped lang="scss">
.profile-button {
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.03) !important;

  color: $text-dark !important;
  font-size: 12px !important;
  font-weight: 700;
  line-height: 20px !important;

  &__text {
    max-width: 180px;
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;

    @media (min-width: map-get($breakpoints, 'xl')) {
      max-width: 250px;
    }
  }

  &.circled {
    width: 48px !important;
  }

  &__arrow {
    transition: transform linear .2s;

    &.active {
      transform: rotateZ(180deg);
    }
  }
}
.profile-menu {
  width: 290px;
  padding-top: 16px;

  background: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: map-get($breakpoints, 'xl')) {
    width: 350px;
  }

  &__content {
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);
  }
  &__icon {
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background: rgba(0, 0, 0, 0.03);
  }
  &__title {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  &__email {
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 16px;
  }
}
</style>
