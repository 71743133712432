<template lang="pug">
  e-button-upload-file(
    ref="uploader"
    button-text="Create"
    :validation-rules="rules"
    accept="application/pdf"
    :loading="loading"
    :secondary-style="secondaryStyle"
    @onload="upload"
  )
</template>

<script>
import Base64js from 'base64-js'
import converters from '~/mixins/methods/converters'
import Template from '~/modules/templates/models/Template'
import EButtonUploadFile from '~/components/elements/buttons/e-button-upload-file'

export default {
  name: 'EButtonTemplateUpload',
  components: {
    EButtonUploadFile
  },
  mixins: [converters],
  props: {
    secondaryStyle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    validationAccept () {
      return 'pdf'
    },
    rules () {
      const rules = 'required|size:35000|ext:'

      return rules + this.validationAccept
    }
  },
  methods: {
    async upload (files) {
      const { valid, errors } = await this.$refs.uploader.validate(files)
      if (!valid) {
        const errMessage = this._.get(errors, '[0]')
        this.$notification.error(errMessage)
        return
      }
      try {
        this.loading = true
        for (const file of files) {
          const arrayBuffer = await this.blobToArrayBuffer(file)
          const uint8View = new Uint8Array(arrayBuffer)
          const base64Str = Base64js.fromByteArray(uint8View)
          try {
            const payload = {
              fileContent: base64Str,
              filename: file?.name,
              title: file?.name.split('.').slice(0, -1).join('') // TODO: or just file.name ?
            }
            const templateId = this._.get(await Template.api().create(payload), 'response.data.id')

            if (files && files.length === 1) {
              await this.$router.push({ path: `/templates/${templateId}` })
              return
            }
            await Template.api().read(templateId)
            this.$notification.success(this.$t('{document} has been successfully uploaded', { document: file.name }))
          } catch (e) {
            const message = this._.get(e, 'response.data.message')
            this.$notification.error(this.$t('Error occurred with {document} - {error}', { document: file.name, error: message }))
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
