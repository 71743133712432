import Document from '~/modules/documents/models/Document'
import DocumentSignatures from '~/modules/documents/models/DocumentSignatures'

const documentUpdate = {
  computed: {
    model () {
      return Document
    },
    signaturesModel () {
      return DocumentSignatures
    }
  },
  methods: {
    async fetchDocument (id) {
      try {
        return await this.model.api().read(id)
      } catch (e) {
        // TODO: check if document exist
        this.$router.push({ name: 'auth-registration', query: { fromDocumentId: id } })
        this.$handlers.error(e, this)
      }
    },
    async fetchSignatures (documentId) {
      try {
        return await this.signaturesModel.api().read(documentId)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}

export default documentUpdate
