<template lang="pug">
  v-app
    v-content
      v-container(fluid fill-height class="default pa-0 background align-start")
        nuxt
    notification
    dialogs(ref="dialogs")
    snackbars
    mobile-drawer
</template>

<script>
import notification from '~/layouts/includes/_notification'
import dialogs from '~/layouts/includes/_dialogs'
import snackbars from '~/layouts/includes/snackbars'
import MobileDrawer from '~/layouts/includes/mobile-drawer'

export default {
  components: {
    MobileDrawer,
    notification,
    dialogs,
    snackbars
  }
}
</script>
