<template lang="pug">
  div(class="documents-sign-status" :class="{ 'modal': isInsideModal }")
    template(v-if="showResult")
      block-documents-sign-results(
        :failed-documents="failedDocuments"
        :close-modal="closeModal"
        :is-inside-modal="isInsideModal"
        @back="showResult = false"
      )
    template(v-else)
      h2(class="documents-sign-status__title") {{ title }}
      div(class="documents-sign-status__content")
        block-documents-sign-list(
          :documents="documents"
          :documents-states="documentsStates"
          :statuses="documentStatuses"
        )
      div(class="documents-sign-status__actions")
        v-btn(v-if="showStop" class="secondary-button error-btn w-100 documents-sign-status__action" @click="stopSign") {{ $t('Stop sign') }}
        template(v-else)
          v-btn(
            v-if="showButton"
            class="main-button documents-sign-status__action" @click="signDocuments"
          ) {{ $t('Continue') }}
</template>

<script>
import Base64js from 'base64-js'
import BlockDocumentsSignResults from '~/modules/documents/components/blocks/block-documents-sign-results'
import BlockDocumentsSignList from '~/modules/documents/components/blocks/block-documents-sign-list'
import Document from '~/modules/documents/models/Document'
import getEdsIitInterfaceOutside from '~/modules/eds-iit-checkbox/mixins/getEdsIitInterfaceOutside'

export default {
  name: 'BlockDocumentsSignStatus',
  components: {
    BlockDocumentsSignList,
    BlockDocumentsSignResults
  },
  mixins: [getEdsIitInterfaceOutside],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    changeDialogWidth: {
      type: Function,
      default: () => {}
    },
    documents: {
      type: Array,
      default: () => []
    },
    sign: {
      type: Function,
      default: () => {}
    },
    // TODO: remove it after fix dialogs padding (t-dialog.vue styles)
    isInsideModal: {
      type: Boolean,
      default: false
    },
    signOnCreate: {
      type: Boolean,
      default: false
    },
    isDiia: {
      type: Boolean,
      default: false
    },
    isSmartId: {
      type: Boolean,
      default: false
    },
    signedDocuments: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isStopped: false,
      showStop: false,
      showResult: false,
      documentsStates: {}
    }
  },
  computed: {
    model () {
      return Document
    },
    title () {
      return !this.showStop ? this.$t('List of documents for signature') : this.$t('Mass documents sign')
    },
    failedDocuments () {
      const failedDocuments = []
      Object.entries(this.documentsStates).filter(([id, value]) => {
        if (this._.get(value, 'status') === this.documentStatuses.error) {
          const document = this._.find(this.documents, document => document.id === id)
          failedDocuments.push({
            title: this._.get(document, 'title'),
            reason: this._.get(value, 'error.message')
          })
        }
      })
      return failedDocuments
    },
    documentStatuses () {
      return {
        signed: 'signed',
        skipped: 'skipped',
        error: 'error',
        created: 'created'
      }
    },
    showButton () {
      const passedDocuments = this._.filter(this.documentsStates, document => document.passed)
      return this._.get(passedDocuments, 'length') < this._.get(this.documents, 'length')
    }
  },
  watch: {
    showResult (val) {
      if (val) {
        if (this.isInsideModal) {
          this.changeDialogWidth('512px')
          return
        }
        this.changeWrapperWidth('512px')
      } else {
        if (this.isInsideModal) {
          this.changeDialogWidth('724px')
          return
        }
        this.changeWrapperWidth('724px')
      }
    }
  },
  created () {
    for (const doc of this.documents) {
      const documentState = { loading: false, error: null, status: this.documentStatuses.created, passed: false }
      this.$set(this.documentsStates, doc.id, documentState)
    }
    if (this.signOnCreate) {
      if (this.isDiia || this.isSmartId) {
        this.signListOfDocumentsByDubidoc(this.signedDocuments)
      } else {
        this.signDocumentsByEdsKey(this.sign)
      }
    }
  },
  methods: {
    async signDocuments () {
      const edsIitInterface = await this.getEdsIitSignInterface(this.documents)
      if (!edsIitInterface) {
        return
      }
      const { isDiia, isSmartId, sign, onConfirmData } = edsIitInterface
      if (isDiia || isSmartId) {
        await this.signListOfDocumentsByDubidoc(onConfirmData)
      } else {
        await this.signDocumentsByEdsKey(sign)
      }
    },
    async signDocumentsByEdsKey (signFn) {
      this.showStop = true
      for (const document of this.documents) {
        if (this.isStopped) {
          if (this.isInsideModal) { // todo: think about closing modal
            this.closeModal(true)
          }
          return
        }
        try {
          this.documentsStates[document.id].loading = true
          if (this._.get(document, 'currentUser', false)) {
            const currentUserStatus = this._.get(document, 'currentUser.status')
            if (currentUserStatus === this.model.DOCUMENT_STATUSES.signed) {
              this.documentsStates[document.id].status = this.documentStatuses.skipped
              continue
            }
          }
          let file
          if ('fileHash' in document) {
            file = Base64js.toByteArray(this._.get(document, 'fileHash', ''))
          } else {
            file = Base64js.toByteArray(this._.get(document, 'file', ''))
          }
          const signedDocument = await signFn(file)
          if (!signedDocument) {
            return
          }
          await this.model.api().sign(document.id, {
            signature: signedDocument
          })
          this.documentsStates[document.id].status = this.documentStatuses.signed
          this.$notification.success(this.$t('Documents has been signed successfully'))
        } catch (e) {
          this.documentsStates[document.id].error = this._.get(e, 'response.data')
          this.documentsStates[document.id].status = this.documentStatuses.error
        } finally {
          this.documentsStates[document.id].passed = true
          this.documentsStates[document.id].loading = false
        }
      }
      if (!this.isInsideModal) {
        this.$emit('success')
      }
      this.showResult = true
      this.showStop = false
    },
    async signListOfDocumentsByDubidoc (documents) {
      this.showStop = true
      for (let i = 0; i < documents.length; i++) {
        const signedDocument = documents[i]
        const documentId = this._.get(signedDocument, 'externalId') || this._.get(this.documents[i], 'id')
        try {
          this.documentsStates[documentId].loading = true
          const signature = this._.get(signedDocument, 'signature') || signedDocument
          await this.model.api().sign(documentId, {
            signature
          })
          this.documentsStates[documentId].status = this.documentStatuses.signed
          this.$notification.success(this.$t('Documents has been signed successfully'))
        } catch (e) {
          this.documentsStates[documentId].error = this._.get(e, 'response.data')
          this.documentsStates[documentId].status = this.documentStatuses.error
        } finally {
          this.documentsStates[documentId].passed = true
          this.documentsStates[documentId].loading = false
        }
      }
      if (!this.isInsideModal) {
        this.$emit('success')
      }
      this.showResult = true
      this.showStop = false
    },
    stopSign () {
      this.isStopped = true
    },
    changeWrapperWidth (width) {
      this.$emit('changeWidth', width)
    }
  }
}
</script>

<style scoped lang="scss">
.documents-sign-status {
  &.modal {
    padding: 24px 0 12px 0;
  }

  &__title {
    color: #000;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    margin-bottom: 40px;
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 32px;
  }

  &__action {
    grid-column: 2 / 3;
  }
}
</style>
