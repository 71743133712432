import Base64js from 'base64-js'
import { mapGetters } from 'vuex'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'
import Organization from '~/modules/organizations/models/Organization'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'
import contentDialog from '~/mixins/dialogs/contentDialog'
import auth from '~/modules/auth/auth'
import getEdsIitInterfaceOutside from '~/modules/eds-iit-checkbox/mixins/getEdsIitInterfaceOutside'
import User from '~/models/administration/User'
import mobileDrawer from '~/modules/mobile-drawer/mixins/mobileDrawer'
import DocumentSignatures from '~/modules/documents/models/DocumentSignatures'
import documentUpdate from '~/modules/documents/mixins/documentUpdate'

const actions = {
  mixins: [documentUpdate, edsIitCheckboxDialog, getEdsIitInterfaceOutside, contentDialog, mobileDrawer],
  computed: {
    ...mapGetters('documents', {
      fileContent: 'fileContent'
    }),
    ...mapGetters('templatefields', {
      filledFields: 'filledFields'
    }),
    actions () {
      return [
        {
          name: 'sign',
          text: 'Sign',
          icon: 'pen',
          visible: document => (this._.get(document, 'currentUser.role') === this.model.USER_ROLES.owner || this._.get(document, 'currentUser.role') === this.model.USER_ROLES.signer),
          call: (document, isTableAction) => this.signDocument(document, isTableAction)
        },
        {
          name: 'share',
          text: 'Share',
          icon: 'file-send-1',
          visible: document => this._.get(document, 'currentUser.role') === this.model.USER_ROLES.owner,
          call: (document, isTableAction) => this.shareDocument(document, isTableAction)
        },
        {
          name: 'archive',
          text: 'Move to archive',
          icon: 'file-archive',
          visible: document => !this._.get(document, 'archived') && this.$User,
          call: (document, isTableAction) => this.archive(document, isTableAction)
        },
        {
          name: 'unarchive',
          text: 'Move to documents',
          icon: 'document',
          visible: document => this._.get(document, 'archived') && this.$User,
          call: (document, isTableAction) => this.unarchive(document, isTableAction)
        },
        {
          name: 'download',
          text: 'Download',
          icon: 'document-download',
          // todo write visibility checker
          visible: () => true,
          call: document => this.downloadDocument(document)
        },
        {
          name: 'delete',
          text: 'Delete',
          icon: 'danger-trash',
          visible: document => this._.get(document, 'canDelete') && this.$User,
          call: (document, isTableAction) => this.deleteDocument(document, isTableAction)
        }
      ]
    },
    mobileActions () {
      return [
        {
          name: 'rename',
          text: 'Rename',
          icon: 'pen-edit',
          visible: document => this._.get(document, 'currentUser.role') === this.model.USER_ROLES.owner,
          call: document => this.rename(document)
        },
        {
          name: 'signatures',
          text: 'Signatures and access',
          icon: 'info-mark',
          visible: () => true,
          call: document => this.showParticipants(document)
        }
      ]
    }
  },
  methods: {
    async shareDocument (document, isTableAction) {
      this.$gtm.push({
        event: 'click_share',
        email: this._.get(this.$User, 'email')
      })
      window.dataLayer && window.dataLayer.push({
        event: 'click_share',
        email: this._.get(this.$User, 'email')
      })
      const hasSentInvites = await this.contentDialog.open({
        width: '724px',
        component: 'form-document-share',
        componentProps: {
          document
        }
      })
      if (isTableAction && hasSentInvites) {
        await this.model.api().read(document.id)
      }
    },
    async archive (document, isTableAction) {
      try {
        this.archiveLoading = true
        await this.model.api().toArchive(document.id)
        if (!isTableAction) {
          await this.model.api().read(document.id)
        } else {
          await this.customRequest({
            model: this.model,
            requestParams: this.requestParams
          })
        }
        this.$notification.success(this.$t('Document has successfully archived'))
        window.dataLayer && window.dataLayer.push({
          event: 'document_archived',
          email: this._.get(this.$User, 'email')
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.archiveLoading = false
      }
    },
    async unarchive (document, isTableAction) {
      try {
        this.unarchiveLoading = true
        await this.model.api().fromArchive(document.id)
        if (!isTableAction) {
          await this.model.api().read(document.id)
        } else {
          await this.customRequest({
            model: this.model,
            requestParams: this.requestParams
          })
        }
        this.$notification.success(this.$t('Document has successfully unarchived'))
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.unarchiveLoading = false
      }
    },
    downloadDocument (document) {
      this.contentDialog.open({
        width: '512px',
        component: 'block-documents-download',
        componentProps: {
          documentId: document.id
        }
      })
    },
    async signDocument (document, isTableAction) {
      window.dataLayer && window.dataLayer.push({
        event: 'click_sign',
        email: this._.get(this.$User, 'email')
      })
      this.signLoading = true
      await this.useEdsIitCheckboxDialog({
        type: 'edsIitCheckboxCommon',
        method: 'open',
        keyCheck: false,
        pluginTitles: {
          options: this.$t('signPlugin.signDocument')
        },
        onConfirm: async ({ dialogClosed, edsKeyData, isDiia, sign }) => {
          if (dialogClosed) {
            return
          } else if (!edsKeyData?.keyData && !isDiia) {
            this.$handlers.error('We could not read the key data. Please try again or choose a different key', this)
            return
          }
          try {
            const filesToSign = []
            const file = Base64js.toByteArray(this._.get(document, 'file', ''))
            let signedFile
            if (isDiia) {
              filesToSign.push({
                name: this._.get(document, 'title'),
                content: file,
                isHashed: true,
                externalId: this._.get(document, 'id')
              })
              signedFile = await sign(filesToSign, { keyCheck: false })
              if (!signedFile || !signedFile.length) {
                return
              }
            } else {
              signedFile = await sign(file, { keyCheck: false, title: 'Підпис файлу: ' + document.title })
              if (!signedFile) {
                return
              }
            }
            await this.model.api().sign(document.id, {
              signature: isDiia ? this._.get(signedFile[0], 'signature') : signedFile
            })
            if (!isTableAction) {
              await this.fetchSignatures(document.id)
              const showOnboardingPopup = this._.get(document, 'currentUser.role') === this.model.USER_ROLES.signer && !this._.get(document, 'currentUser.registered') && !auth.getAuthToken()
              if (showOnboardingPopup) {
                await this.callToRegister()
              }
            } else {
              await this.model.api().read(document.id)
              this.$notification.success(this.$t('Document has been signed successfully'))
            }
            if (!Organization.all().length && !auth.getGuestAuthToken() && auth.getAuthToken()) {
              await Organization.api().all()
              currentOrganizationService.setCurrentOrganization(Organization.query().first())
              await User.api().setCurrentOrganization({ organizationId: this._.get(Organization.query().first(), 'id') })
            }
            window.dataLayer && window.dataLayer.push({
              event: 'document_signed',
              email: this._.get(this.$User, 'email')
            })
            this.signLoading = false
          } catch (e) {
            this.$handlers.error(e, this)
          }
        }
      })
      this.signLoading = false
    },
    // TODO: refactor this
    async signDocumentWithDynamicFields (document) {
      try {
        const updatedDocument = await this.fillDynamicFields(document)
        await this.signDocument(updatedDocument)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async shareDocumentWithDynamicFields (document) {
      try {
        const updatedDocument = await this.fillDynamicFields(document)
        await this.shareDocument(updatedDocument)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async deleteDocument (document, isTableAction) {
      try {
        await this.model.api().del(document)
        this.$notification.success(this.$t('Document has been deleted successfully'))
        if (!isTableAction) {
          this.$router.push('/documents')
        }
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    // table mass actions methods
    async archiveListOfDocuments () {
      this.archiveLoading = true
      for (const document of this.selected) {
        try {
          await this.model.api().toArchive(document.id)
          await this.customRequest({
            model: this.model,
            requestParams: this.requestParams
          })
        } catch (e) {
          this.$handlers.error(e, this)
        }
      }
      this.archiveLoading = false
      this.selected = []
      this.$notification.success(this.$t('Documents have successfully archived'))
    },
    async unarchiveListOfDocuments () {
      this.unarchiveLoading = true
      for (const document of this.selected) {
        try {
          await this.model.api().fromArchive(document.id)
          await this.customRequest({
            model: this.model,
            requestParams: this.requestParams
          })
        } catch (e) {
          this.$handlers.error(e, this)
        }
      }
      this.unarchiveLoading = false
      this.selected = []
      this.$notification.success(this.$t('Documents have successfully unarchived'))
    },
    async signListOfDocuments () {
      this.listSignLoading = true
      const edsIitInterface = await this.getEdsIitSignInterface(this.selected)
      if (!edsIitInterface) {
        return
      }
      const { isDiia, isSmartId, sign, onConfirmData } = edsIitInterface
      await this.contentDialog.open({
        width: '724px',
        component: 'block-documents-sign-status',
        componentProps: {
          documents: this.selected,
          sign,
          isInsideModal: true,
          isDiia,
          isSmartId,
          signedDocuments: onConfirmData,
          signOnCreate: true
        }
      })
      this.selected = []
      await this.customRequest({
        model: this.model,
        requestParams: this.requestParams
      })
      this.listSignLoading = false
    },
    async downloadListOfDocuments () {
      await this.contentDialog.open({
        width: '512px',
        component: 'block-documents-download',
        componentProps: {
          documentIds: this._.map(this.selected, item => item.id)
        }
      })
    },
    rename (document) {
      this.mobileDrawer.open({
        title: 'Rename',
        component: 'form-document-rename',
        componentProps: {
          model: this.model,
          document
        }
      })
    },
    showParticipants (document) {
      const signatures = DocumentSignatures.all()
      this.mobileDrawer.open({
        title: 'Signatures and access',
        // todo: calculate height of drawer by component height
        height: '70%',
        background: '#F6F9FD',
        component: 'form-document-signatures',
        componentProps: {
          model: this.model,
          document,
          signatures
        }
      })
    },
    showShare (document) {
      this.mobileDrawer.open({
        title: 'Share this document',
        // todo: calculate height of drawer by component height
        height: '85%',
        component: 'form-document-share',
        componentProps: {
          document
        }
      })
    },
    // TODO: maybe move to other mixin
    async callToRegister () {
      await this.contentDialog.open({
        width: '512px',
        component: 'block-documents-success',
        componentProps: {
          title: 'Document has been signed',
          text: 'The sender will receive a message that you have signed the document. To start working with documents in the Dubidoc service, we recommend creating an account, it takes 30 seconds.',
          buttonText: 'Create an account',
          onSubmit: () => this.$router.push('/auth')
        }
      })
    },
    setFillMode (value) {
      this.$store.commit('templatefields/SET_FILL_MODE', value)
    },
    async fillDynamicFields (document) {
      try {
        const payload = {
          fields: this.filledFields,
          fileContent: this.fileContent
        }
        await this.model.api().fillDynamicFields(document.id, payload)
        this.$store.commit('templatefields/RESET_FILLED_FIELDS')
        return this._.get(await this.model.api().read(document.id), 'response.data')
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}

export default actions
