import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import PersistAble from '~/models/mixins/PersistAble'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'

export default class Template extends ChainInheritance(OrmModel, [PersistAble]) {
  static entity = 'template'
  static paginated = true
  static defaultSortParam = 'createdAt'

  static fields () {
    return {
      id: this.attr(null),
      createdAt: this.attr(null),
      title: this.attr(null),
      fileContent: this.attr(null),
      fields: this.attr(null)
    }
  }

  get handleDate () {
    return this.getDate(this.createdAt)
  }

  static ormHeaders = [
    { text: 'Name', value: 'title', sortable: true, filterable: true },
    { text: 'Date uploading', value: 'handleDate', sortable: true, filterable: true, sortQuery: 'createdAt' },
    { text: 'Actions', align: 'center', value: 'actions', width: '120', sortable: false, filterable: false }
  ]

  static ormActions = [
    {
      name: 'delete',
      text: 'Delete',
      icon: 'danger-trash',
      // todo write visibility checker
      visible: () => true,
      call: async (item) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        const confirmed = await confirmationDialog.open({
          title: 'Delete template',
          text: 'Are you sure to delete this template?',
          width: '420px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          }
        })
        if (confirmed) {
          try {
            await Template.api().del(item)
            AppNotifications.success('Template has deleted successfully')
          } catch (e) {
            const message = this._.get(e, 'response.data.detail', null) || this._.get(e, 'response.data.message', null)
            AppNotifications.error(message)
          }
        }
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.createField = function (id, payload) {
        return this.post(Model.$routes.template.fields(id), payload, { save: false })
      }
      configActions.updateField = function (id, fieldId, payload) {
        return this.put(Model.$routes.template.concreteFields(id, fieldId), payload, { save: false })
      }
      configActions.deleteField = function (id, fieldId) {
        return this.delete(Model.$routes.template.concreteFields(id, fieldId), { save: false })
      }

      configActions.fromTemplateToDocument = function (id) {
        return this.post(Model.$routes.template.toDocument(id), {}, { save: false })
      }

      return configActions
    }
  }
}
