import Vue from 'vue'
import { cloneDeep } from 'lodash'
import TemplateField from '~/modules/templates/models/TemplateField'

export const state = () => ({
  lastActiveFieldId: null,
  activeField: {
    id: null,
    offsetX: null,
    offsetY: null
  },
  copiedField: null,
  cuttedField: null,
  isDraggingMap: {},
  toolbarsMap: {},
  isFillMode: false,
  filledFields: []
})

export const getters = {
  activeFieldId: state => state.activeField.id,
  activeFieldOffsetX: state => state.activeField.offsetX,
  activeFieldOffsetY: state => state.activeField.offsetY,
  lastActiveFieldId: state => state.lastActiveFieldId,
  copiedField: state => state.copiedField,
  cuttedField: state => state.cuttedField,
  isDraggingMap: state => state.isDraggingMap,
  toolbarsMap: state => state.toolbarsMap,
  isFillMode: state => state.isFillMode,
  filledFields: state => state.filledFields
}

export const mutations = {
  SET_ACTIVE_FIELD_ID (state, id) {
    state.activeField.id = id
  },
  SET_LAST_ACTIVE_FIELD_ID (state, id) {
    state.lastActiveFieldId = id
  },
  SET_ACTIVE_FIELD_OFFSET_X (state, offset) {
    state.activeField.offsetX = offset
  },
  SET_ACTIVE_FIELD_OFFSET_Y (state, offset) {
    state.activeField.offsetY = offset
  },
  SET_COPIED_FIELD (state, field) {
    state.copiedField = field
  },
  SET_CUTTED_FIELD (state, field) {
    state.cuttedField = field
  },
  // TODO: rethink maybe
  SET_DRAGGING_MAP_FIELD_ID (state, { id, value }) {
    Vue.set(state.isDraggingMap, id, value)
  },
  SET_TOOLBARS_MAP_FIELD_ID (state, { id, value }) {
    Vue.set(state.toolbarsMap, id, value)
  },
  REMOVE_DRAGGING_MAP_FIELD (state, id) {
    Vue.delete(state.isDraggingMap, id)
  },
  REMOVE_TOOLBARS_MAP_FIELD (state, id) {
    Vue.delete(state.toolbarsMap, id)
  },
  SET_FILL_MODE (state, value) {
    state.isFillMode = value
  },
  SET_FILLED_FIELD (state, field) {
    state.filledFields.push(field)
  },
  REMOVE_FILLED_FIELD (state, fieldId) {
    state.filledFields = state.filledFields.filter(field => field.id !== fieldId)
  },
  RESET_FILLED_FIELDS (state) {
    state.filledFields = []
  },
  RESET_ALL_TOOLBARS_EXCEPT_ACTIVE (state, fieldId) {
    Object.entries(state.toolbarsMap).forEach(([id, value]) => {
      if (fieldId !== id && value) {
        state.toolbarsMap[id] = false
      }
    })
  }
}

export const actions = {
  pasteField ({ commit, state }, field) {
    const COPY_OFFSET = 15
    const clonedField = cloneDeep(field)
    const clonedFieldKey = clonedField.key.split('_')
    const clonedFieldKeyIndex = clonedFieldKey[clonedFieldKey.length - 1]
    const lastYFieldPosition = clonedField.positionY
    const nextYFieldPosition = clonedField.positionY + clonedField.height + COPY_OFFSET
    const isCut = !!state.cuttedField
    const pasteField = {
      ...clonedField,
      positionY: isCut ? lastYFieldPosition : nextYFieldPosition,
      key: clonedField.key + '_' + clonedFieldKeyIndex
    }
    if (isCut && pasteField.status === TemplateField.STATUSES.to_delete) {
      pasteField.status = TemplateField.STATUSES.created
    } else {
      delete pasteField.id
      pasteField.status = TemplateField.STATUSES.new
    }
    TemplateField.insert({
      data: pasteField
    })
  },
  updateField ({ commit }, { updatedData, id }) {
    TemplateField.update({
      where: id,
      data: updatedData
    })
  }
}
