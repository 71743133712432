<template lang="pug">
  v-app
    _drawer(v-if="!xsDevice")
    _header(v-else)
    v-content(:key="key")
      v-container(fill-height fluid class="default pa-0 align-start bg")
        nuxt
    notification
    dialogs(ref="dialogs")
    sidebars
    snackbars
</template>

<script>
import notification from './includes/_notification'
import dialogs from './includes/_dialogs'
import _drawer from '~/layouts/includes/_drawer'
import _header from '~/layouts/includes/_header'
import sidebars from '~/modules/sidebar/views/components/sidebars'
import responsive from '~/mixins/pages/responsive'
import snackbars from '~/layouts/includes/snackbars'

export default {
  components: {
    snackbars,
    _drawer,
    notification,
    dialogs,
    sidebars,
    _header
  },
  mixins: [responsive],
  data () {
    return {
      key: 1
    }
  },
  methods: {
    rerenderContent () {
      this.key = Date.now()
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  min-width: 320px !important;
  overflow: hidden;
}
.bg {
  background: #F6F9FD;
}
</style>
